import React, { FC, ForwardRefRenderFunction, InputHTMLAttributes, forwardRef, useCallback } from 'react'
import cx from 'classnames'

import CheckImage from 'svgs/property-plan/check'

import styles from './Checkbox.module.scss'

const Checkbox: ForwardRefRenderFunction<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>> = (
  { className, ...others },
  ref
) => {
  return (
    <div className={cx(styles.checkbox, className)}>
      <input type="checkbox" {...others} ref={ref} />
      <button type="button">
        <CheckImage className={styles.check} />
      </button>
    </div>
  )
}

const ForwardedCheckBox = forwardRef(Checkbox)

interface HandledCheckboxProps {
  className?: string
  id?: string
  checked: boolean
  onChange: (checked: boolean) => void
  value?: string
}

// For those legacy spots requiring an onChange that invokes with a boolean
export const HandledCheckbox: FC<HandledCheckboxProps> = ({ onChange, ...props }) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked)
    },
    [onChange]
  )

  return <ForwardedCheckBox {...props} onChange={handleChange} />
}

export default ForwardedCheckBox
