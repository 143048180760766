export interface FetchResponse<T = any> {
  isError: boolean
  code: number
  rawBody: string
  jsonBody: T
}

export async function getJSON<T>(path: string, options?: Partial<RequestInit>): Promise<T> {
  const headers = new Headers(options?.headers)
  headers.set('Accept', 'application/json')
  headers.set('X-Requested-With', 'XMLHttpRequest')
  if (process.env.REALM_APP_VERSION) headers.set('X-Realm-App-Version', process.env.REALM_APP_VERSION)
  const response = await fetch(path, {
    credentials: 'same-origin',
    headers,
    ...options,
  })

  // Get needs to return what we expect, not error results.
  if (response.status < 200 || response.status >= 300) {
    // Check if there is a message to attach.
    let msgObj
    try {
      // Make sure the response is JSON before grabbing it.
      if (response.headers.get('content-type')?.startsWith('application/json')) {
        msgObj = { error: response.json() }
      }
    } catch {
      /* Ok if not */
    }
    const errorMessage =
      response.status >= 500 && response.status < 600
        ? `5xx status code: ${response.status}.`
        : `Non-200 status code: ${response.status}.`

    const error = Object.assign(new Error(errorMessage), { code: response.status }, msgObj)

    throw error
  }
  return response.json()
}

export async function patchJSON<T = any>(
  path: string,
  body: Record<string, unknown>,
  signal?: AbortSignal
): Promise<FetchResponse<T>> {
  return sendJSON(path, body, 'PATCH', signal)
}

export async function postJSON<T = any>(
  path: string,
  body: Record<string, unknown>,
  signal?: AbortSignal
): Promise<FetchResponse<T>> {
  return sendJSON(path, body, 'POST', signal)
}

export async function deleteJSON(path: string, signal?: AbortSignal): Promise<FetchResponse> {
  return sendJSON(path, null, 'DELETE', signal)
}

export function sendJSON(
  path,
  body,
  method: 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  signal?: AbortSignal
): Promise<FetchResponse> {
  const param = document.querySelector('meta[name=csrf-param]')?.getAttribute('content') || ''
  const token = document.querySelector('meta[name=csrf-token]')?.getAttribute('content') || ''

  const headers = new Headers()
  headers.set('Accept', 'application/json')
  headers.set('Content-Type', 'application/json')
  headers.set('X-Requested-With', 'XMLHttpRequest')
  if (process.env.REALM_APP_VERSION) headers.set('X-Realm-App-Version', process.env.REALM_APP_VERSION)

  return handleResponse(() =>
    fetch(path, {
      method,
      credentials: 'same-origin',
      headers,
      body: JSON.stringify({
        ...body,
        [param]: token,
      }),
      signal,
    })
  )
}

export async function postFormData(path: string, formData: FormData): Promise<FetchResponse> {
  // No need to set 'Content-Type' header; it's set automatically by the browser
  // when FormData is passed to the fetch body.
  const headers = new Headers()
  headers.set('Accept', 'application/json')
  headers.set('X-Requested-With', 'XMLHttpRequest')
  if (process.env.REALM_APP_VERSION) headers.set('X-Realm-App-Version', process.env.REALM_APP_VERSION)

  // CSRF token handling
  const param = document.querySelector('meta[name=csrf-param]')?.getAttribute('content')
  const token = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')
  if (param && token) {
    formData.append(param, token)
  }

  return handleResponse(() =>
    fetch(path, {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body: formData,
    })
  )
}

export async function patchFormData(path: string, formData: FormData): Promise<FetchResponse> {
  // No need to set 'Content-Type' header; it's set automatically by the browser
  // when FormData is passed to the fetch body.
  const headers = new Headers()
  headers.set('Accept', 'application/json')
  headers.set('X-Requested-With', 'XMLHttpRequest')
  if (process.env.REALM_APP_VERSION) headers.set('X-Realm-App-Version', process.env.REALM_APP_VERSION)

  // CSRF token handling
  const param = document.querySelector('meta[name=csrf-param]')?.getAttribute('content')
  const token = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')
  if (param && token) {
    formData.append(param, token)
  }

  return handleResponse(() =>
    fetch(path, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers,
      body: formData,
    })
  )
}

export async function postFile(
  path: string,
  body: FormData,
  method: string = 'POST',
  signal?: AbortSignal
): Promise<FetchResponse> {
  // CSRF token handling
  const param = document.querySelector('meta[name=csrf-param]')?.getAttribute('content')
  const token = document.querySelector('meta[name=csrf-token]')?.getAttribute('content')
  if (param && token) {
    body.append(param, token)
  }

  return handleResponse(() =>
    fetch(path, {
      method,
      credentials: 'same-origin',
      body,
      signal,
    })
  )
}

export async function patchFile(path: string, body: FormData): Promise<FetchResponse> {
  return handleResponse(() =>
    fetch(path, {
      method: 'PATCH',
      credentials: 'same-origin',
      body,
    })
  )
}

export async function deleteFile(path: string, body: FormData | null): Promise<FetchResponse> {
  return handleResponse(() =>
    fetch(path, {
      method: 'DELETE',
      credentials: 'same-origin',
      body,
    })
  )
}

async function handleResponse(fetcher: () => Promise<Response>): Promise<FetchResponse> {
  const response = await fetcher()
  const rawBody = await response.text()
  const contentType = response.headers.get('content-type')
  let jsonBody = null
  if (contentType && contentType.indexOf('application/json') > -1 && rawBody != null && rawBody != '') {
    jsonBody = JSON.parse(rawBody)
  }

  if (response.status >= 500 && response.status < 600) {
    throw new Error(`5xx status code: ${response.status}.`)
  }
  return {
    isError: response.status < 200 || response.status > 299,
    code: response.status,
    rawBody,
    jsonBody,
  }
}
